import React, { useEffect,createContext, useState } from 'react';
import { useMemo } from 'react';

export const userAuth = createContext({});

const UserContextProvider = ({ children }) => {

	const [token, setToken] = useState({});
	//console.log('AABBCC', token);
	useEffect(() => {
		const gettoken = JSON.parse(localStorage.getItem("user-auth"));
		if (gettoken) {
		  setToken(gettoken);
		}
	  }, []);

	const values = useMemo(
		() => ({
			token,
			setToken,
		}),
		[token],
	);
	return <userAuth.Provider value={values}>{children}</userAuth.Provider>;
};

export default UserContextProvider;
