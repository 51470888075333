const constantString = {
	ACTIVATE: 'Activate',
	DEACTIVATE: 'Deactivate',
	UPDATE: 'Update',
	REQUIERD: '*Required',
	SAVE: 'Save',
	CHOOSE: 'Choose...',
	NEW_SHIFT: 'New Shift',
	EDIT_SHIFT: 'Edit Shift',
	EDIT: 'Edit',
	SHIFTS: 'Shifts',
	ID: 'ID',
	ORDERID: 'Order Id',
	NAME: 'Name',
	DATE: 'Date',
	START_HOUR: 'Start hour',
	END_HOUR: 'End hour',
	STATUS: 'Status',
	ACTION: 'Action',
	SHOW: 'Show',
	ALLOW_ONLY_IMG: '*Allow Image Only',
	UPLOADING: 'Uploading...',
	P_APPOINTMENTS: 'Pending Appointments',
	TIME_SLOT: 'Time Slot',
	SERVICE: 'Service',
	CUST_NAME: 'Customer Name',
	EMP_NAME: 'Employee Name',
	EMP_ASSIGN_STATUS: 'Emp Assign Status',
	DURATION: 'Duration',
	PRICE: 'Price',
	PAID_AMOUNT: 'Paid Amount',
	P_REFUND: 'Pending Refund',
	COMP_REF:'Completed Refunds',
	REDUND_AMOUNT: 'Redund Amount',
	ACCEPTED: 'Accepted',
	PENDING: 'Pending',
	RELEASED: 'Released',
	NOT_PAID: 'Not Paid',
	EXPIRED: 'Expired',
	ITEMS: 'items',
	EDIT_APPOINTMENT: 'Edit Appointment',
	COMPLETE: 'Complete',
	CANCEL: 'Cancel',
	REFUND_AMOUNT: 'Refund Amount',
	REQUEST_REFUND: 'Request Refund',
	ADD_REFUND: 'Add Refund',
	PAID: 'Paid',
	SUBMIT: 'Submit',
	UPDATE_EMP: 'Update Employee',
	COMP_APPOINTMENT: 'Completed Appointments',
	PENDING: 'Pending',
	CANCEL: 'Cancel',
	ASSIGN_EMP: 'Assign Employee',
	ASSIGN_EMPs: 'Assign Employees',
	CANCEL_APPOINTMENT: 'Cancel Appointments',
	PROMOTIONS: 'Promotions',
	ONE_TIME_PER_CUST: 'One time per customer',
	ULTI_TIME_PER_CUST: 'Unlimited time per customer',
	DEDUCT_TYPE: 'Deduct Type',
	END_DATE: 'End date',
	START_DATE: 'Start date',
	DESCRIPTION: 'Description',
	PROMO_CODE: 'Promo Code',
	EDIT_PROMOTION: 'Edit Promotion',
	SELECT_D_TYPE_F : 'Select Deduct Type First', 
	ENTER_PERCENT: 'Enter percentage',
	ENTER_AMOUNT: 'Enter Amount',
	AMOUNT_EXCEED_LIMIT:'Amount exceeds the limit',
	USAGE_TYPE:'Usage Type',
	DEDUCT_PERCENT: 'Deduct Percentage',
	PERCENTAGE: 'Percentage',
	FIXED:'Fixed',
	NEW_PROMOTION: 'New Promotion',
	CAT_NAME:'Category Name',
	NEW_CAT:'New Category',
	ARE_Y_SURE: 'Are you sure?',
	WANT_CHANGE_APPOINT: 'Are you sure you want to change this appointment?',
	WANT_CHANGE_IMG: 'Are you sure you want to delete this image?',
	DASHBOARD: 'Dashboard',
	ACTIVE_STATE: 'Active',
	INACTIVE_STATE: 'Inactive',
};

export default constantString;
